"use client";

import { createContext, useState, useEffect, ReactNode, useCallback } from "react";
import { Organization } from "@/@codegen/supergraph/graphql";
import { getUserOrganizationsAPI, ExtendedOrganization } from "@/api/organization";
import { useAuth } from "@/hooks/auth";
import { useNotice } from "@/components/edges/ui/Notice/NoticeProvider";
import { gql, useApolloClient } from "@apollo/client";
import { useEventEmitter } from "../event-bus";
import { useTranslations } from "next-intl";
import { organizationNotices } from "./notices";
import { useRouter } from "next/navigation";
interface CreateOrganizationInput {
  name: string;
  industry?: string;
  logo?: string;
  preferredTemperatureUnit?: string;
  domain?: string;
}
interface OrganizationContextType {
  organizations: ExtendedOrganization[];
  currentOrganization: Organization | null;
  currentOrganizationId: string | null;
  switchOrganization: (organizationId: string) => Promise<boolean | void>;
  createOrganization: (input: CreateOrganizationInput) => Promise<void>;
  error: string | null;
  checkWorkspaceAccess: (workspaceId: string) => Promise<{
    hasAccess: boolean;
    organizationId?: string;
  }>;
}
export const OrganizationContext = createContext<OrganizationContextType>({
  organizations: [],
  currentOrganization: null,
  currentOrganizationId: null,
  switchOrganization: async () => {},
  createOrganization: async () => {},
  error: null,
  checkWorkspaceAccess: async () => ({
    hasAccess: false
  })
});
export const OrganizationProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const [organizations, setOrganizations] = useState<ExtendedOrganization[]>([]);
  const [currentOrganization, setCurrentOrganization] = useState<Organization | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isSwitching, setIsSwitching] = useState(false);
  const [isAuthFlow, setIsAuthFlow] = useState(false);
  const {
    user,
    refreshSession
  } = useAuth();
  const {
    addNotice
  } = useNotice();
  const client = useApolloClient();
  const emitter = useEventEmitter();
  const t = useTranslations("loading");
  const router = useRouter();

  // Helper to identify personal organizations
  const isPersonalOrganization = useCallback((org: Organization) => {
    return Boolean(org.personal);
  }, []);

  // Core fetch function - just gets the data
  const fetchOrganizations = useCallback(async (flowId?: string) => {
    try {
      console.log("[OrganizationProvider] Fetching organizations with flowId:", flowId);

      // Ensure we have user data before proceeding
      if (!user) {
        return;
      }
      setError(null);
      const allOrganizations = await getUserOrganizationsAPI(client);

      // Filter out personal organizations
      const filteredOrganizations = allOrganizations.filter(org => !isPersonalOrganization(org));
      setOrganizations(filteredOrganizations);

      // Only set authenticated organization if we have non-personal orgs
      if (filteredOrganizations.length > 0) {
        const authenticatedOrganization = filteredOrganizations.find(org => org.authenticated === true);
        if (authenticatedOrganization) {
          setCurrentOrganization(authenticatedOrganization);
          emitter.emit("organization:initialized", {
            currentOrganizationId: authenticatedOrganization.id,
            flowId
          });
          // Reset auth flow after emitting the event
          if (flowId) {
            setIsAuthFlow(false);
          }
        }
      } else {
        // Check if we're on the invite-members or test-invites page
        const isInviteMembersPage = window.location.pathname === "/invite-members";
        const isTestInvitesPage = window.location.pathname === "/test-invites";

        // Only redirect to create-organization if we're not on invite-members or test-invites
        if (!isInviteMembersPage && !isTestInvitesPage) {
          setCurrentOrganization(null);
          // Don't redirect to create-organization if email isn't verified
          if (user?.isEmailVerified) {
            emitter.emit("navigation:navigate", {
              path: "/create-organization",
              options: {
                replace: true
              },
              flowId
            });
            // Reset auth flow after navigation
            if (flowId) {
              setIsAuthFlow(false);
            }
          }
        }
      }
    } catch (error) {
      console.error("[OrganizationProvider] Failed to fetch organizations:", error);
      setError("Failed to load organizations");
      addNotice(organizationNotices.fetchError);
      emitter.emit("navigation:navigate", {
        path: "/sign-in",
        options: {
          replace: true
        },
        flowId
      });
      // Reset auth flow on error
      if (flowId) {
        setIsAuthFlow(false);
      }
    }
  }, [client, emitter, addNotice, isPersonalOrganization, user?.isEmailVerified]);

  // Handle auth flow
  useEffect(() => {
    const handleSignIn = ({
      flowId,
      userId
    }: {
      userId: string;
      flowId?: string;
    }) => {
      if (flowId) {
        setIsAuthFlow(true);
        emitter.emit("loading:update", {
          text: t("organization"),
          flowId
        });
        fetchOrganizations(flowId);
      }
    };
    emitter.on("auth:signedIn", handleSignIn);
    return () => {
      emitter.off("auth:signedIn", handleSignIn);
    };
  }, [emitter, fetchOrganizations, t]);

  // Add effect to retry fetch when user becomes available during auth flow
  useEffect(() => {
    if (isAuthFlow && user && !organizations.length) {
      fetchOrganizations("auth-signin-flow");
    }
  }, [user, isAuthFlow, organizations.length, fetchOrganizations]);

  // Initial fetch and cleanup when user changes
  useEffect(() => {
    let isCleanedUp = false;
    if (user && !isAuthFlow) {
      // Check for pending invites before fetching organizations
      const hasPendingInvites = user.invitations?.some(invite => invite.status === "PENDING");

      // Only fetch organizations if there are no pending invites and we haven't cleaned up
      if (!hasPendingInvites && !isCleanedUp) {
        fetchOrganizations();
      }
    } else {
      if (!isCleanedUp) {
        setOrganizations([]);
        setCurrentOrganization(null);
        setError(null);
      }
    }
    return () => {
      isCleanedUp = true;
    };
  }, [user, fetchOrganizations, isAuthFlow]);
  const checkWorkspaceAccess = async (workspaceId: string) => {
    try {
      const {
        data
      } = await client.query({
        query: gql`
          query CheckWorkspaceAccess($workspaceId: String!) {
            workspace(id: $workspaceId) {
              id
              organizationId
            }
          }
        `,
        variables: {
          workspaceId
        }
      });
      if (!data?.workspace) {
        return {
          hasAccess: false
        };
      }
      const workspaceOrganizationId = data.workspace.organizationId;

      // Check if user has access to this organization and it's not a personal org
      const organization = organizations.find(org => org.id === workspaceOrganizationId);
      const hasAccess = Boolean(organization && !isPersonalOrganization(organization));
      return {
        hasAccess,
        organizationId: hasAccess ? workspaceOrganizationId : undefined
      };
    } catch (error) {
      console.error("[OrganizationProvider] Failed to check workspace access:", error);
      return {
        hasAccess: false
      };
    }
  };
  const switchOrganization = useCallback(async (organizationId: string) => {
    if (isSwitching) return;
    setIsSwitching(true);
    const flowId = "organization-switch-flow";
    try {
      emitter.emit("loading:start", {
        text: t("switching"),
        flowId
      });
      const targetOrganization = organizations.find(org => org.id === organizationId);
      if (!targetOrganization || isPersonalOrganization(targetOrganization)) {
        throw new Error("Organization not found or is a personal organization");
      }
      setCurrentOrganization(targetOrganization);
      await refreshSession(organizationId, true);
      await client.clearStore();
      emitter.emit("organization:initialized", {
        currentOrganizationId: organizationId,
        flowId
      });
      setIsSwitching(false);
      return true;
    } catch (error) {
      console.error("[OrganizationProvider] Failed to switch organization:", error);
      setError("Failed to switch organization");
      addNotice(organizationNotices.switchError);
      emitter.emit("loading:end", {
        flowId
      });
      setIsSwitching(false);
      throw error;
    }
  }, [isSwitching, organizations, refreshSession, client, emitter, addNotice, t, isPersonalOrganization]);
  const createOrganization = useCallback(async (input: CreateOrganizationInput) => {
    const flowId = "organization-create-flow";
    try {
      emitter.emit("loading:start", {
        text: t("creating_organization"),
        flowId
      });

      // Create the organization
      const organization = await client.mutate({
        mutation: gql`
            mutation CreateOrganization($input: CreateOrganizationInput!) {
              createOrganization(input: $input) {
                id
                name
                industry
                logo
                preferredTemperatureUnit
              }
            }
          `,
        variables: {
          input: {
            ...input,
            domain: input.domain || ""
          }
        }
      });
      if (!organization.data?.createOrganization) {
        throw new Error("Failed to create organization");
      }

      // Refresh session with new organization
      await refreshSession(organization.data.createOrganization.id, true);

      // Instead of emitting organization:initialized, redirect to invite-members
      emitter.emit("navigation:navigate", {
        path: "/invite-members",
        options: {
          replace: true
        },
        flowId
      });
    } catch (error) {
      console.error("[OrganizationProvider] Failed to create organization:", error);
      addNotice({
        title: "Failed to Create Organization",
        description: "There was a problem creating your organization. Please try again.",
        variant: "error",
        position: "center"
      });
      emitter.emit("loading:end", {
        flowId
      });
      throw error;
    }
  }, [client, refreshSession, emitter, addNotice, t]);
  return <OrganizationContext.Provider value={{
    organizations,
    currentOrganization,
    currentOrganizationId: currentOrganization?.id || null,
    switchOrganization,
    createOrganization,
    error,
    checkWorkspaceAccess
  }} data-sentry-element="unknown" data-sentry-component="OrganizationProvider" data-sentry-source-file="OrganizationProvider.tsx">
      {children}
    </OrganizationContext.Provider>;
};
OrganizationProvider.displayName = "OrganizationProvider";