"use client";

import { createContext, useState, useEffect, ReactNode } from "react";
import { loginAPI, logoutAPI, refreshTokenAPI, requestPasswordResetAPI, resetPasswordAPI, signUpAPI } from "@/api/auth";
import { useRouter, usePathname } from "next/navigation";
import { useNotice } from "@/components/edges/ui/Notice/NoticeProvider";
import { decodeJwt } from "jose";
import { useApolloClient } from "@apollo/client";
import { useEventEmitter } from "../event-bus";
import { useTranslations } from "next-intl";
import { ME_QUERY } from "./queries";
import { User, MeQueryResponse, SignInCredentials, SignUpCredentials, ApiError, AuthContextType, Membership, PostHogApi } from "./types";
import { gql } from "@apollo/client";

// Extend Window interface to include posthog
declare global {
  interface Window {
    posthog?: PostHogApi;
  }
}
export const AuthContext = createContext<AuthContextType | null>(null);

// Note here we are intentionally changing the token and refresh token keys in localStorage
// to avoid conflicts with the legacy auth provider
export const AuthProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const [user, setUser] = useState<User | null>(null);
  const [isInitializing, setIsInitializing] = useState(true);
  const [token, setToken] = useState<string | null>(() => {
    if (typeof window === "undefined") return null;
    const storedToken = localStorage.getItem("accessToken");
    return storedToken && storedToken !== "undefined" ? storedToken : null;
  });
  const [refreshToken, setRefreshToken] = useState<string | null>(() => {
    if (typeof window === "undefined") return null;
    const storedRefreshToken = localStorage.getItem("refreshToken");
    return storedRefreshToken && storedRefreshToken !== "undefined" ? storedRefreshToken : null;
  });
  const router = useRouter();
  const pathname = usePathname();
  const {
    addNotice
  } = useNotice();
  const client = useApolloClient();
  const emitter = useEventEmitter();
  const t = useTranslations("loading");

  // Initial session verification
  useEffect(() => {
    const initializeSession = async () => {
      try {
        setIsInitializing(true);
        // If we have a refresh token but no valid token, try to refresh first
        if (refreshToken && (!token || !isAuthenticated())) {
          await refreshSession(undefined, true);
        } else if (token) {
          await verifySession(true);
        }
      } catch (error) {
        console.error("[AuthProvider] Failed to initialize session:", error);
        // Clear everything on initialization failure
        setUser(null);
        setToken(null);
        setRefreshToken(null);

        // Clear from localStorage
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");

        // Clear cookies
        document.cookie = "accessToken=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT; SameSite=Strict";
        document.cookie = "refreshToken=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT; SameSite=Strict";
      } finally {
        setIsInitializing(false);
      }
    };
    initializeSession();
  }, []);

  // Add effect to sync tokens with localStorage and cookies
  useEffect(() => {
    if (token && token !== "undefined") {
      localStorage.setItem("accessToken", token);
      // Also set as cookie for middleware access - using HttpOnly: false to ensure JS can access it
      document.cookie = `accessToken=${token}; path=/; max-age=86400; SameSite=Lax`;
    } else {
      // Clear token if it's not valid
      localStorage.removeItem("accessToken");
      document.cookie = "accessToken=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
    if (refreshToken && refreshToken !== "undefined") {
      localStorage.setItem("refreshToken", refreshToken);
      // Also set as cookie for middleware access
      document.cookie = `refreshToken=${refreshToken}; path=/; max-age=86400; SameSite=Lax`;
    } else {
      // Clear refresh token if it's not valid
      localStorage.removeItem("refreshToken");
      document.cookie = "refreshToken=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }, [token, refreshToken]);

  // Add polling for email verification status when on confirm-email page
  useEffect(() => {
    if (!user?.email || pathname !== "/confirm-email") {
      return;
    }

    // Poll for email verification status every 2 seconds
    const interval = setInterval(async () => {
      try {
        const {
          data
        } = await client.query({
          query: gql`
            query CheckEmailVerificationStatus($email: String!) {
              checkEmailVerificationStatus(email: $email)
            }
          `,
          variables: {
            email: user.email
          },
          fetchPolicy: "network-only" // Don't cache the result
        });
        if (data?.checkEmailVerificationStatus) {
          // Email is verified, first refresh the token to get new verification status
          await refreshSession(undefined, true);

          // Then refresh the session to get updated user data
          await verifySession();

          // Clear the interval since we're verified
          clearInterval(interval);

          // Emit auth:signedIn to trigger org loading
          emitter.emit("auth:signedIn", {
            userId: user.id
          });
        }
      } catch (error) {
        console.error("[AuthProvider] Failed to check email verification status:", error);
      }
    }, 2000);

    // Cleanup interval on unmount
    return () => clearInterval(interval);
  }, [user?.email]);
  const isAuthenticated = () => {
    if (!token || token === "undefined") return false;
    try {
      const decodedToken = decodeJwt(token);
      const dateNow = new Date().getTime() / 1000;
      return (decodedToken.exp ?? 0) > dateNow;
    } catch {
      return false;
    }
  };
  const verifySession = async (skipLoading = false) => {
    try {
      // If no token, fail fast
      if (!token) {
        throw new Error("No token available");
      }
      const {
        data
      } = await client.query<MeQueryResponse>({
        query: ME_QUERY,
        context: {
          headers: {
            Authorization: `Bearer ${token}`
          }
        },
        // Ensure we get fresh data
        fetchPolicy: "network-only"
      });
      if (!data?.me) {
        throw new Error("Session verification failed");
      }

      // Check if user is a Texture employee by email domain
      const email = data.me.email?.email || "";
      const isTextureDomain = email.endsWith("@texturehq.com");

      // If email has Texture domain, make sure isTextureEmployee is set to true
      const isTextureEmployee = isTextureDomain ? true : data.me.isTextureEmployee;
      const user: User = {
        id: data.me.id,
        name: data.me.profile?.fullName || "",
        email,
        preferredTemperatureUnit: data.me.profile?.preferredTemperatureUnit,
        preferredTimeZone: data.me.profile?.preferredTimeZone,
        profilePhoto: data.me.profile?.profilePhoto,
        isEmailVerified: data.me.email?.verified,
        isTextureEmployee,
        memberships: data.me.memberships,
        invitations: data.me.invitations
      };

      // If the email verification status has changed, force a token refresh
      if (user.isEmailVerified !== data?.me?.email?.verified) {
        await refreshSession(undefined, skipLoading);
        return user;
      }
      setUser(user);

      // If email is not verified and we're not already on the confirm email page, redirect
      if (!user.isEmailVerified && !["/confirm-email", "/verify-email"].includes(pathname)) {
        router.replace("/confirm-email");
        return user;
      }

      // No longer need to check for memberships here since OrganizationProvider handles this
      return user;
    } catch (error) {
      console.error("[AuthProvider] Session verification failed:", error);

      // Clear everything on verification failure
      setUser(null);
      setToken(null);
      setRefreshToken(null);

      // Clear from localStorage
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");

      // Clear cookies
      document.cookie = "accessToken=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT; SameSite=Strict";
      document.cookie = "refreshToken=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT; SameSite=Strict";
      throw error;
    }
  };
  const signIn = async (credentials: {
    email: string;
    password: string;
    organizationId?: string;
    rememberMe?: boolean;
  }) => {
    const flowId = "auth-signin-flow";
    try {
      console.log("[AuthProvider] Starting sign in with flowId:", flowId);
      emitter.emit("loading:start", {
        text: t("signing_in"),
        flowId
      });
      const {
        bearerToken,
        refreshToken
      } = await loginAPI(client, credentials);

      // Validate tokens before storing
      if (!bearerToken || !refreshToken) {
        emitter.emit("loading:end", {
          flowId
        });
        throw new Error("Invalid tokens received from server");
      }

      // Set tokens in localStorage first (synchronous)
      localStorage.setItem("accessToken", bearerToken);
      localStorage.setItem("refreshToken", refreshToken);

      // Then update state
      setToken(bearerToken);
      setRefreshToken(refreshToken);

      // Verify session using the token we just got
      const {
        data
      } = await client.query<MeQueryResponse>({
        query: ME_QUERY,
        context: {
          headers: {
            Authorization: `Bearer ${bearerToken}`
          }
        },
        fetchPolicy: "network-only"
      });
      console.log("[AuthProvider] Verify session - ME_QUERY response:", {
        id: data?.me?.id,
        hasInvitations: Boolean(data?.me?.invitations),
        invitationsCount: data?.me?.invitations?.length
      });
      if (!data?.me) {
        throw new Error("Session verification failed");
      }

      // Check if user is a Texture employee by email domain
      const email = data.me.email?.email || "";
      const isTextureDomain = email.endsWith("@texturehq.com");

      // If email has Texture domain, make sure isTextureEmployee is set to true
      const isTextureEmployee = isTextureDomain ? true : data.me.isTextureEmployee;
      const user: User = {
        id: data.me.id,
        name: data.me.profile?.fullName || "",
        email,
        preferredTemperatureUnit: data.me.profile?.preferredTemperatureUnit,
        preferredTimeZone: data.me.profile?.preferredTimeZone,
        profilePhoto: data.me.profile?.profilePhoto,
        isEmailVerified: data.me.email?.verified,
        isTextureEmployee,
        memberships: data.me.memberships,
        invitations: data.me.invitations
      };
      setUser(user);

      // If email is not verified, redirect to confirm email page and end loading
      if (!user.isEmailVerified) {
        emitter.emit("loading:end", {
          flowId
        });
        router.push("/confirm-email");
        return;
      }

      // Check for pending invitations and emit appropriate event
      const hasPendingInvites = user.invitations?.some(invite => invite.status === "PENDING");
      if (hasPendingInvites) {
        console.log("[AuthProvider] Emitting hasPendingInvites with flowId:", flowId);
        emitter.emit("auth:hasPendingInvites", {
          userId: user.id,
          flowId
        });
      } else {
        console.log("[AuthProvider] Emitting signedIn with flowId:", flowId);
        emitter.emit("auth:signedIn", {
          userId: user.id,
          flowId
        });
      }
    } catch (error) {
      console.error("[AuthProvider] Sign-in error:", error);
      emitter.emit("loading:end", {
        flowId
      });
      emitter.emit("auth:signIn:failed");

      // Clear any partially set state
      setUser(null);
      setToken(null);
      setRefreshToken(null);

      // Clear from localStorage
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");

      // Clear cookies
      document.cookie = "accessToken=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT; SameSite=Strict";
      document.cookie = "refreshToken=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT; SameSite=Strict";
      addNotice({
        title: "Login Failed",
        description: (error as ApiError).response?.status === 401 ? "Invalid email or password. Please try again." : "Something went wrong. Please try again later.",
        variant: "error",
        persistent: true,
        position: "center"
      });
      throw error;
    }
  };
  const refreshSession = async (organizationId?: string, skipLoading = false) => {
    try {
      if (!refreshToken) {
        throw new Error("No refresh token available");
      }
      const {
        bearerToken,
        refreshToken: newRefreshToken
      } = await refreshTokenAPI(client, refreshToken, organizationId);
      setToken(bearerToken);
      setRefreshToken(newRefreshToken);
      await verifySession(skipLoading);
    } catch (error) {
      console.error("[AuthProvider] Failed to refresh session:", error);
      logout(true);
    }
  };
  const logout = async (sessionExpired = false) => {
    try {
      emitter.emit("loading:start", {
        text: t("signing_out"),
        flowId: "auth-signout-flow"
      });

      // Emit auth event that LoadingGuard will listen for
      emitter.emit("auth:signOut:started");
      await logoutAPI();

      // Reset PostHog identity when logging out
      try {
        if (typeof window !== "undefined" && window.posthog && typeof window.posthog.reset === "function") {
          window.posthog.reset();
        }
      } catch (error) {
        console.error("[AuthProvider] Failed to reset PostHog identity:", error);
      }

      // Clear from localStorage
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");

      // Clear cookies
      document.cookie = "accessToken=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT";
      document.cookie = "refreshToken=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT";
      console.log("[AuthProvider] All auth tokens cleared");
      setUser(null);
      setToken(null);
      setRefreshToken(null);

      // Emit auth:signedOut for navigation
      emitter.emit("auth:signedOut");
      emitter.emit("loading:end", {
        flowId: "auth-signout-flow"
      });
      router.push("/sign-in");
    } catch (error) {
      console.error("[AuthProvider] Logout failed:", error);
      addNotice({
        title: "Logout Failed",
        description: "Something went wrong while logging out.",
        variant: "error",
        persistent: true,
        position: "center"
      });
      // Emit auth event for error state
      emitter.emit("auth:signOut:failed");
    }
  };
  const requestPasswordReset = async (email: string) => {
    try {
      emitter.emit("auth:passwordReset:started");
      const result = await requestPasswordResetAPI(client, email);
      emitter.emit("auth:passwordReset:completed");
      return result;
    } catch (error) {
      console.error("[AuthProvider] Password reset request failed:", error);
      emitter.emit("auth:passwordReset:failed");
      addNotice({
        title: "Password Reset Failed",
        description: "Failed to request password reset. Please try again.",
        variant: "error",
        position: "top-right"
      });
      throw error;
    }
  };
  const resetPassword = async (email: string, password: string) => {
    try {
      emitter.emit("auth:passwordReset:started");
      const success = await resetPasswordAPI(client, email);
      if (success) {
        addNotice({
          title: "Password Reset Successful",
          description: "Your password has been reset successfully. Please sign in with your new password.",
          variant: "success",
          position: "top-right"
        });
        emitter.emit("auth:passwordReset:completed");
      } else {
        throw new Error("Failed to reset password");
      }
      return {
        success,
        message: "Password reset successful"
      };
    } catch (error) {
      console.error("[AuthProvider] Password reset failed:", error);
      emitter.emit("auth:passwordReset:failed");
      addNotice({
        title: "Password Reset Failed",
        description: "Failed to reset your password. Please try again.",
        variant: "error",
        position: "top-right"
      });
      throw error;
    }
  };
  const signUp = async (credentials: SignUpCredentials) => {
    const flowId = "auth-signup-flow";
    try {
      emitter.emit("loading:start", {
        text: t("signing_up"),
        flowId
      });

      // First sign up - only request id field
      const {
        data: signUpData
      } = await client.mutate({
        mutation: gql`
          mutation SignUpAuthProvider($input: SignUpUserInput!) {
            signUp(input: $input) {
              id
            }
          }
        `,
        variables: {
          input: credentials
        }
      });
      if (!signUpData?.signUp?.id) {
        throw new Error("Unexpected sign up payload");
      }

      // Then immediately sign in
      const {
        bearerToken,
        refreshToken
      } = await loginAPI(client, {
        email: credentials.email,
        password: credentials.password,
        rememberMe: true
      });

      // Validate tokens before storing
      if (!bearerToken || !refreshToken) {
        emitter.emit("loading:end", {
          flowId
        });
        throw new Error("Invalid tokens received from server");
      }

      // Set tokens in localStorage first
      localStorage.setItem("accessToken", bearerToken);
      localStorage.setItem("refreshToken", refreshToken);

      // Then update state
      setToken(bearerToken);
      setRefreshToken(refreshToken);

      // Verify session using the token we just got
      const {
        data
      } = await client.query<MeQueryResponse>({
        query: ME_QUERY,
        context: {
          headers: {
            Authorization: `Bearer ${bearerToken}`
          }
        },
        fetchPolicy: "network-only"
      });
      if (!data?.me) {
        throw new Error("Session verification failed");
      }

      // Check if user is a Texture employee by email domain
      const email = data.me.email?.email || credentials.email;
      const isTextureDomain = email.endsWith("@texturehq.com");

      // If email has Texture domain, make sure isTextureEmployee is set to true
      const isTextureEmployee = isTextureDomain ? true : data.me.isTextureEmployee;
      const user: User = {
        id: data.me.id,
        name: data.me.profile?.fullName || `${credentials.firstName} ${credentials.lastName}`,
        email,
        preferredTemperatureUnit: data.me.profile?.preferredTemperatureUnit,
        preferredTimeZone: data.me.profile?.preferredTimeZone,
        profilePhoto: data.me.profile?.profilePhoto,
        isEmailVerified: data.me.email?.verified,
        isTextureEmployee,
        memberships: data.me.memberships,
        invitations: data.me.invitations
      };
      setUser(user);

      // First check if email needs verification
      if (!user.isEmailVerified) {
        router.push("/confirm-email");
        emitter.emit("loading:end", {
          flowId
        });
        return;
      }

      // Check for pending invitations and emit appropriate event
      const hasPendingInvites = user.invitations?.some(invite => invite.status === "PENDING");
      if (hasPendingInvites) {
        // If there are pending invites, only emit that event
        emitter.emit("auth:hasPendingInvites", {
          userId: user.id,
          flowId
        });
      } else {
        // Only emit auth:signedIn if there are no pending invites
        emitter.emit("auth:signedIn", {
          userId: user.id,
          flowId
        });
      }
    } catch (error) {
      console.error("[AuthProvider] Sign-up error:", error);

      // Clear any partially set state
      setUser(null);
      setToken(null);
      setRefreshToken(null);

      // Clear from localStorage
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");

      // Clear cookies
      document.cookie = "accessToken=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT; SameSite=Strict";
      document.cookie = "refreshToken=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT; SameSite=Strict";
      addNotice({
        title: "Sign Up Failed",
        description: "Failed to create your account. Please try again.",
        variant: "error",
        persistent: true,
        position: "center"
      });
      throw error;
    } finally {
      emitter.emit("loading:end", {
        flowId
      });
    }
  };
  const resendVerificationEmail = async () => {
    try {
      if (!user?.email) {
        throw new Error("No user email available");
      }
      await client.mutate({
        mutation: gql`
          mutation ResendVerificationEmail(
            $input: ResendVerificationEmailInput!
          ) {
            resendVerificationEmail(input: $input)
          }
        `,
        variables: {
          input: {
            email: user.email
          }
        }
      });
      addNotice({
        title: "Verification Email Sent",
        description: "A new verification email has been sent to your inbox.",
        variant: "success",
        position: "top-right"
      });
    } catch (error) {
      console.error("[AuthProvider] Failed to resend verification email:", error);
      addNotice({
        title: "Failed to Send Email",
        description: "Unable to send verification email. Please try again later.",
        variant: "error",
        position: "top-right"
      });
      throw error;
    }
  };
  return <AuthContext.Provider value={{
    user,
    token,
    refreshToken,
    signIn,
    signUp,
    signOut: logout,
    loading: isInitializing,
    isAuthenticated: isAuthenticated(),
    refreshSession,
    requestPasswordReset,
    resetPassword,
    resendVerificationEmail
  }} data-sentry-element="unknown" data-sentry-component="AuthProvider" data-sentry-source-file="AuthProvider.tsx">
      {children}
    </AuthContext.Provider>;
};
AuthProvider.displayName = "AuthProvider";