"use client";

import { createContext, useContext, useState, useCallback } from "react";
import { NoticeContainer } from "./NoticeContainer";
export type Notice = {
  id: string;
  title: string;
  description?: string;
  variant?: "info" | "success" | "warning" | "error";
  duration?: number; // 0 = stays until dismissed
  action?: {
    label: string;
    onClick: () => void;
  };
  position?: "top-right" | "bottom-right" | "center";
  persistent?: boolean; // If true, requires manual dismiss
};
type NoticeContextType = {
  addNotice: (notice: Omit<Notice, "id">) => void;
  removeNotice: (id: string) => void;
};
export const NoticeContext = createContext<NoticeContextType | undefined>(undefined);
export function NoticeProvider({
  children
}: {
  children: React.ReactNode;
}) {
  const [notices, setNotices] = useState<Notice[]>([]);
  const addNotice = useCallback((notice: Omit<Notice, "id">) => {
    const id = crypto.randomUUID();
    setNotices(prev => [...prev, {
      id,
      ...notice
    }]);
    if (!notice.persistent && notice.duration !== 0) {
      setTimeout(() => removeNotice(id), notice.duration || 5000);
    }
  }, []);
  const removeNotice = useCallback((id: string) => {
    setNotices(prev => prev.filter(n => n.id !== id));
  }, []);
  return <NoticeContext.Provider value={{
    addNotice,
    removeNotice
  }} data-sentry-element="unknown" data-sentry-component="NoticeProvider" data-sentry-source-file="NoticeProvider.tsx">
      {children}
      <NoticeContainer notices={notices} removeNotice={removeNotice} data-sentry-element="NoticeContainer" data-sentry-source-file="NoticeProvider.tsx" />
    </NoticeContext.Provider>;
}
export function useNotice() {
  const context = useContext(NoticeContext);
  if (!context) {
    throw new Error("useNotice must be used within a NoticeProvider");
  }
  return context;
}