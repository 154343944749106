import { gql } from "@apollo/client";

export const ME_QUERY = gql`
  query LoggedUserProvider {
    me {
      id
      profile {
        fullName
        preferredTemperatureUnit
        preferredTimeZone
        profilePhoto
      }
      email {
        email
        verified
      }
      areEmailsVerified
      isTextureEmployee
      invitations {
        createdAt
        email
        id
        invitedByProfile {
          fullName
          firstName
          lastName
          profilePhoto
        }
        organization {
          id
          memberCount
          name
          owner
        }
        role
        sentAt
        status
        updatedAt
        userExists
        userId
      }
      memberships {
        id
        role
        isAdmin
        organization {
          id
          name
        }
      }
    }
  }
`;
