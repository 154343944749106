"use client";

import { motion } from "framer-motion";
import type { Notice as NoticeType } from "./NoticeProvider";
import { twMerge } from "tailwind-merge";
const variants = {
  info: "text-feedback-info-text",
  success: "text-feedback-success-text",
  warning: "text-feedback-warning-text",
  error: "text-feedback-error-text"
};
export function Notice({
  notice,
  removeNotice,
  isFullWidth = false
}: {
  notice: NoticeType;
  removeNotice: (id: string) => void;
  isFullWidth?: boolean;
}) {
  return <motion.div initial={{
    opacity: 0,
    y: -10
  }} animate={{
    opacity: 1,
    y: 0
  }} exit={{
    opacity: 0,
    y: -10
  }} transition={{
    duration: 0.3
  }} data-testid="notice" className={twMerge("flex items-center justify-between rounded-lg border bg-[--background-surface] p-4 shadow-lg", variants[notice.variant || "info"], isFullWidth ? "w-full max-w-3xl px-6" : "w-80")} data-sentry-element="unknown" data-sentry-component="Notice" data-sentry-source-file="Notice.tsx">
      <div>
        <div className="text-sm font-semibold">{notice.title}</div>
        {notice.description && <p className="text-sm text-[--text-body]">{notice.description}</p>}
      </div>
      {notice.action && <button onClick={notice.action.onClick} className="ml-4 text-sm font-medium underline hover:opacity-80" aria-label={`${notice.action.label} for notice: ${notice.title}`}>
          {notice.action.label}
        </button>}
      <button onClick={() => removeNotice(notice.id)} className="ml-4 text-xl hover:opacity-80" aria-label="Close notice">
        &times;
      </button>
    </motion.div>;
}