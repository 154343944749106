import type { Notice } from "@/components/edges/ui/Notice/NoticeProvider";

type NoticeWithoutId = Omit<Notice, "id">;

export const organizationNotices = {
  fetchError: {
    title: "Error",
    description: "Failed to load organizations. Please try again later.",
    variant: "error",
    position: "top-right",
  } as NoticeWithoutId,

  switchError: {
    title: "Error",
    description: "Failed to switch organization. Please try again later.",
    variant: "error",
    position: "top-right",
  } as NoticeWithoutId,
} as const;
