import { ApolloClient, gql } from "@apollo/client";

interface Membership {
  id: string;
  role: string;
  isAdmin: boolean;
  organization: {
    id: string;
    name: string;
  };
}

interface MeQueryResponse {
  me: {
    id: string;
    createdAt: string;
    isTextureEmployee: boolean;
    areEmailsVerified: boolean;
    profile: {
      id: string;
      preferredTemperatureUnit?: string;
      preferredTimeZone?: string;
      firstName?: string;
      lastName?: string;
      fullName?: string;
      profilePhoto?: string;
    };
    email: {
      id: string;
      email: string;
      verified: boolean;
      domain: string;
      isPersonalEmailDomain: boolean;
    };
    memberships: Membership[];
  };
}

interface LoginCredentials {
  email: string;
  password: string;
  organizationId?: string;
  rememberMe?: boolean;
}

interface LoginResponse {
  bearerToken: string;
  refreshToken: string;
  user: MeQueryResponse["me"];
}

interface AuthenticateResponse {
  authenticateByEmailPassword: {
    bearerToken: string;
    refreshToken: string;
  };
}

interface AuthenticateVariables {
  input: {
    email: string;
    password: string;
    organizationId?: string;
  };
}

interface RefreshTokenResponse {
  refreshAuthenticationSession: {
    bearerToken: string;
    refreshToken: string;
  };
}

interface RefreshTokenVariables {
  input: {
    organizationId?: string;
  };
}

// interface ResetPasswordResponse {
//   requestPasswordReset: {
//     success: boolean;
//     message: string;
//   };
// }

// interface ResetPasswordVariables {
//   input: {
//     email: string;
//   };
// }

interface ChangePasswordResponse {
  changePassword: boolean;
}

interface ChangePasswordVariables {
  input: {
    email: string;
  };
}

interface RequestPasswordResetResponse {
  changePassword: boolean;
}

interface RequestPasswordResetVariables {
  input: {
    email: string;
  };
}

interface SignUpUserInput {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
}

const AUTHENTICATE_MUTATION = gql`
  mutation AuthenticateByEmailPassword(
    $input: AuthenticateByEmailPasswordInput!
  ) {
    authenticateByEmailPassword(input: $input) {
      bearerToken
      refreshToken
    }
  }
`;

const ME_QUERY = gql`
  query LoggedUserAuth {
    me {
      id
      createdAt
      isTextureEmployee
      areEmailsVerified
      profile {
        id
        preferredTemperatureUnit
        preferredTimeZone
        firstName
        lastName
        fullName
        profilePhoto
      }
      email {
        id
        email
        verified
        domain
        isPersonalEmailDomain
      }
      memberships {
        id
        role
        isAdmin
        organization {
          id
          name
        }
      }
    }
  }
`;

const REFRESH_TOKEN_MUTATION = gql`
  mutation RefreshAuthenticationSession(
    $input: RefreshAuthenticationSessionInput!
  ) {
    refreshAuthenticationSession(input: $input) {
      bearerToken
      refreshToken
    }
  }
`;

const REQUEST_PASSWORD_RESET_MUTATION = gql`
  mutation ChangePassword($input: ChangePasswordInput!) {
    changePassword(input: $input)
  }
`;

const CHANGE_PASSWORD_MUTATION = gql`
  mutation ChangePassword($input: ChangePasswordInput!) {
    changePassword(input: $input)
  }
`;

const SIGN_UP_MUTATION = gql`
  mutation SignUpAPI($input: SignUpUserInput!) {
    signUp(input: $input) {
      id
      firstName
      lastName
      fullName
    }
  }
`;

export const loginAPI = async (
  client: ApolloClient<unknown>,
  credentials: LoginCredentials,
): Promise<LoginResponse> => {
  const { data: authData } = await client.mutate<
    AuthenticateResponse,
    AuthenticateVariables
  >({
    mutation: AUTHENTICATE_MUTATION,
    variables: {
      input: {
        email: credentials.email,
        password: credentials.password,
        organizationId: credentials.organizationId,
      },
    },
  });

  if (!authData?.authenticateByEmailPassword) {
    throw new Error("Authentication failed");
  }

  const { bearerToken, refreshToken } = authData.authenticateByEmailPassword;

  // Store the token in localStorage so the authLink can use it
  localStorage.setItem("accessToken", bearerToken);
  if (credentials.rememberMe) {
    localStorage.setItem("refreshToken", refreshToken);
  }

  // Also set cookies for server components to use
  document.cookie = `accessToken=${bearerToken}; path=/; max-age=86400; SameSite=Strict`;
  if (credentials.rememberMe) {
    document.cookie = `refreshToken=${refreshToken}; path=/; max-age=2592000; SameSite=Strict`;
  }

  // Get user data with the new token
  const { data: userData } = await client.query<MeQueryResponse>({
    query: ME_QUERY,
    context: {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
      },
    },
  });

  if (!userData?.me) {
    throw new Error("Failed to fetch user data");
  }

  return {
    bearerToken,
    refreshToken,
    user: userData.me,
  };
};

export const refreshTokenAPI = async (
  client: ApolloClient<unknown>,
  refreshToken: string,
  organizationId?: string,
): Promise<{ bearerToken: string; refreshToken: string }> => {
  const { data } = await client.mutate<
    RefreshTokenResponse,
    RefreshTokenVariables
  >({
    mutation: REFRESH_TOKEN_MUTATION,
    variables: {
      input: {
        organizationId,
      },
    },
    context: {
      headers: {
        "X-Texture-Refresh-Token": refreshToken,
      },
    },
  });

  if (!data?.refreshAuthenticationSession) {
    throw new Error("Failed to refresh token");
  }

  const newBearerToken = data.refreshAuthenticationSession.bearerToken;
  const newRefreshToken = data.refreshAuthenticationSession.refreshToken;

  // Set cookies for server components
  document.cookie = `accessToken=${newBearerToken}; path=/; max-age=86400; SameSite=Strict`;
  document.cookie = `refreshToken=${newRefreshToken}; path=/; max-age=2592000; SameSite=Strict`;

  return {
    bearerToken: newBearerToken,
    refreshToken: newRefreshToken,
  };
};

export const logoutAPI = async (): Promise<void> => {
  // Since we're using JWT tokens, we don't need to make a server call
  // Clear the tokens from localStorage
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");

  // Also clear the cookies
  document.cookie =
    "accessToken=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT; SameSite=Strict";
  document.cookie =
    "refreshToken=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT; SameSite=Strict";
};

export const requestPasswordResetAPI = async (
  client: ApolloClient<unknown>,
  email: string,
): Promise<{ success: boolean; message: string }> => {
  const { data } = await client.mutate<
    RequestPasswordResetResponse,
    RequestPasswordResetVariables
  >({
    mutation: REQUEST_PASSWORD_RESET_MUTATION,
    variables: {
      input: {
        email,
      },
    },
  });

  if (data?.changePassword === undefined) {
    throw new Error("Failed to request password reset");
  }

  return {
    success: data.changePassword,
    message:
      "If an account exists with this email, you will receive password reset instructions.",
  };
};

export const resetPasswordAPI = async (
  client: ApolloClient<unknown>,
  email: string,
): Promise<boolean> => {
  const { data } = await client.mutate<
    ChangePasswordResponse,
    ChangePasswordVariables
  >({
    mutation: CHANGE_PASSWORD_MUTATION,
    variables: {
      input: {
        email,
      },
    },
  });

  if (data?.changePassword === undefined) {
    throw new Error("Failed to change password");
  }

  return data.changePassword;
};

export const signUpAPI = async (
  client: ApolloClient<unknown>,
  credentials: SignUpUserInput,
) => {
  const { data } = await client.mutate({
    mutation: SIGN_UP_MUTATION,
    variables: {
      input: credentials,
    },
  });

  const user = data.signUp;

  // After successful sign-up, authenticate the user
  const { data: authData } = await client.mutate<
    AuthenticateResponse,
    AuthenticateVariables
  >({
    mutation: AUTHENTICATE_MUTATION,
    variables: {
      input: {
        email: credentials.email,
        password: credentials.password,
      },
    },
  });

  if (!authData?.authenticateByEmailPassword) {
    throw new Error("Authentication failed after sign-up");
  }

  const { bearerToken, refreshToken } = authData.authenticateByEmailPassword;

  // Store tokens in localStorage
  localStorage.setItem("accessToken", bearerToken);
  localStorage.setItem("refreshToken", refreshToken);

  // Also set cookies for server components
  document.cookie = `accessToken=${bearerToken}; path=/; max-age=86400; SameSite=Strict`;
  document.cookie = `refreshToken=${refreshToken}; path=/; max-age=2592000; SameSite=Strict`;

  return {
    bearerToken,
    refreshToken,
    user,
  };
};
