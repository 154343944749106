"use client";

import { AnimatePresence } from "framer-motion";
import type { Notice as NoticeType } from "./NoticeProvider";
import { Notice } from "./Notice";
export function NoticeContainer({
  notices,
  removeNotice
}: {
  notices: NoticeType[];
  removeNotice: (id: string) => void;
}) {
  return <>
      {/* Top Right Notices */}
      <div className="fixed right-4 top-4 z-50 flex flex-col space-y-2">
        <AnimatePresence data-sentry-element="AnimatePresence" data-sentry-source-file="NoticeContainer.tsx">
          {notices.filter(n => n.position === "top-right").map(notice => <Notice key={notice.id} notice={notice} removeNotice={removeNotice} />)}
        </AnimatePresence>
      </div>

      {/* Bottom Right Notices */}
      <div className="fixed bottom-4 right-4 z-50 flex flex-col space-y-2">
        <AnimatePresence data-sentry-element="AnimatePresence" data-sentry-source-file="NoticeContainer.tsx">
          {notices.filter(n => n.position === "bottom-right").map(notice => <Notice key={notice.id} notice={notice} removeNotice={removeNotice} />)}
        </AnimatePresence>
      </div>

      {/* Centered Notices */}
      <div className="fixed left-0 top-0 z-50 mt-6 flex w-full flex-col items-center space-y-2">
        <AnimatePresence data-sentry-element="AnimatePresence" data-sentry-source-file="NoticeContainer.tsx">
          {notices.filter(n => n.position === "center").map(notice => <Notice key={notice.id} notice={notice} removeNotice={removeNotice} isFullWidth />)}
        </AnimatePresence>
      </div>
    </>;
}