"use client";

import { createContext, useContext, useEffect, useRef } from "react";
import mitt, { Emitter } from "mitt";
interface NavigateParams {
  path: string;
  options?: {
    replace?: boolean;
  };
  flowId?: string;
}

// Define your application's events here
export type Events = {
  // Auth events
  "auth:signIn:started": void;
  "auth:signIn:failed": void;
  "auth:signedIn": {
    userId: string;
    flowId?: string;
  };
  "auth:hasPendingInvites": {
    userId: string;
    flowId?: string;
  };
  "auth:signOut:started": void;
  "auth:signOut:failed": void;
  "auth:signedOut": void;
  "auth:sessionExpired": void;

  // Organization events
  "organization:fetch:started": void;
  "organization:fetch:completed": void;
  "organization:initialized": {
    currentOrganizationId: string;
    flowId?: string;
  };
  "organization:fetch:failed": void;
  "organization:switch:started": void;
  "organization:switch:failed": void;
  "organization:switched": {
    organizationId: string;
  };
  "organization:created": {
    organizationId: string;
  };
  "organization:updated": {
    organizationId: string;
  };
  "organization:deleted": {
    organizationId: string;
  };

  // Workspace events
  "workspace:fetch:started": void;
  "workspace:fetch:completed": void;
  "workspace:fetch:failed": void;
  "workspace:action:started": {
    action: string;
  };
  "workspace:action:completed": void;
  "workspace:action:failed": void;
  "workspace:switched": {
    workspaceId: string;
  };
  "workspace:created": {
    workspaceId: string;
  };
  "workspace:updated": {
    workspaceId: string;
  };
  "workspace:deleted": {
    workspaceId: string;
  };

  // Loading events
  "loading:start": {
    text: string;
    flowId?: string;
  };
  "loading:update": {
    text: string;
    flowId?: string;
  };
  "loading:end": {
    flowId?: string;
  };

  // Navigation events
  "navigation:started": {
    to: string;
  };
  "navigation:completed": void;
  "navigation:navigate": NavigateParams;

  // Notice events
  "notice:add": {
    message: string;
    type?: "success" | "error" | "info" | "warning";
  };
  "notice:remove": {
    id: string;
  };
  "notice:clear": void;

  // Required for Record<string, unknown> constraint
  [key: string]: unknown;
};
const EventEmitterContext = createContext<Emitter<Events> | null>(null);
export function useEventEmitter() {
  const emitter = useContext(EventEmitterContext);
  if (!emitter) {
    throw new Error("useEventEmitter must be used within an EventEmitterProvider");
  }
  return emitter;
}
type EventEmitterProviderProps = {
  children: React.ReactNode;
};
export function EventEmitterProvider({
  children
}: EventEmitterProviderProps) {
  const emitterRef = useRef<Emitter<Events>>();
  if (!emitterRef.current) {
    emitterRef.current = mitt<Events>();
  }

  // Clean up event listeners when the provider is unmounted
  useEffect(() => {
    const emitter = emitterRef.current;
    return () => {
      emitter?.all.clear();
    };
  }, []);
  return <EventEmitterContext.Provider value={emitterRef.current} data-sentry-element="unknown" data-sentry-component="EventEmitterProvider" data-sentry-source-file="event-bus.tsx">
      {children}
    </EventEmitterContext.Provider>;
}