import { ApolloClient, gql } from "@apollo/client";
import { Organization } from "@/@codegen/supergraph/graphql";

// Extended organization type with authentication flag
export interface ExtendedOrganization extends Organization {
  authenticated?: boolean;
}

// Re-export the Organization type from GraphQL
export type { Organization };

const GET_USER_ORGANIZATIONS = gql`
  query GetUserOrganizations {
    me {
      authenticatedOrganization {
        id
        name
        domain
        logo
        preferredTemperatureUnit
        industry
        personal
      }
      memberships {
        organization {
          id
          name
          domain
          logo
          preferredTemperatureUnit
          industry
          personal
        }
      }
    }
  }
`;

export const getUserOrganizationsAPI = async (
  client: ApolloClient<unknown>,
): Promise<ExtendedOrganization[]> => {
  // Explicitly disable cache for this critical query
  const { data } = await client.query({
    query: GET_USER_ORGANIZATIONS,
    fetchPolicy: "network-only", // Don't use cache for this query
  });

  if (!data?.me) {
    throw new Error("Failed to fetch user organizations");
  }

  // Get unique organizations by combining authenticated org and memberships
  const organizations: ExtendedOrganization[] = [];
  const authOrgId = data.me.authenticatedOrganization?.id;

  // Add the authenticated organization first if it exists
  if (data.me.authenticatedOrganization) {
    // Mark this organization as authenticated for our UI
    const authOrg: ExtendedOrganization = {
      ...data.me.authenticatedOrganization,
      authenticated: true,
    };
    organizations.push(authOrg);
  }

  // Add other organizations from memberships if they're not already included
  data.me.memberships?.forEach((membership: { organization: Organization }) => {
    if (!organizations.some((org) => org.id === membership.organization.id)) {
      // Mark if this is the authenticated org
      const isAuthOrg = membership.organization.id === authOrgId;
      const org: ExtendedOrganization = {
        ...membership.organization,
        authenticated: isAuthOrg,
      };
      organizations.push(org);
    }
  });
  return organizations;
};
